/* .badge-content-container {

    display: flex;
    justify-content: flex-end;
    width: 80%;
    margin-left: 24px;
    height: 600px;
    position: relative;
    padding: 5.375rem 0 0;
    background-size: contain;
    background-image: url(../../../assets/images/stage.png)
} */




.badge-content-container {
    position: relative;
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: flex-end;
    padding-left: 22px;
    background: url('../../../assets/images/stage.png') rgba(0, 0, 0, 0.5);
    background-blend-mode: overlay;

    background-position: center;
    background-size: cover;
    overflow: hidden;
    margin-top: 12px;
}



.slider-container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;

    /* Adjust based on your needs */
    overflow: hidden;
}

.column {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.image-wrapper {
    width: 180px;
    height: 180px;
    background-size: 100% 180px;
    object-fit: contain;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    color: white;
    border-radius: 20px;

}

.first-badge-container {
    animation: slideDown 20s linear infinite;
}

.second-badge-container {
    animation: slideUp 20s linear infinite;
}

@keyframes slideDown {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-50%);
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(-50%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes slideLeft {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}

@keyframes slideRight {
    0% {
        transform: translateX(-50%);
    }

    100% {
        transform: translateX(0);
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {

    .badge-content-container {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        padding-left: 22px;
        background: url('../../../assets/images/stage.png') rgba(0, 0, 0, 0.5);
        background-blend-mode: overlay;
    
        background-position: center;
        background-size: cover;
        overflow: hidden;
        margin-top: 12px;
    }
    

    .slider-container {
        display: flex;
        flex-direction: column;
        height: auto;
        align-items: center;
    }

    .column {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
    }

    .image-wrapper {
        width: 120px !important;
        height: 120px !important;
        background-size: 120px 120px !important;
        object-fit: contain;
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
        color: white;
        border-radius: 20px;

    }

    .badge-detail-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }

    .hide-on-mobile {
        display: none;
    }


    .first-badge-container {
        animation: slideLeft 10s linear infinite;
    }

    .second-badge-container {
        animation: slideRight 10s linear infinite;
    }

    .badge-slider-container {
        width: 100%;
    }


    .badge-info-container {
        width: 120px !important;
        display: flex;
        flex-direction: column;
        padding: 12px;
        background-color: rgba(0, 0, 0, 0.4);
        border: 1px solid #FFFFFF33;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

}

.badge-title {
    font-size: 13px;
    color: white;
}

.badge-level {
    font-size: 10px;
    color: white;
}

.badge-info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px;
    background-color: rgba(0, 0, 0, 0.4);
    border: 1px solid #FFFFFF33;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.badge-info-container > .badge-title {
    font-size: 10px;
    color: white;
}

.badge-info-container > .badge-level {
    font-size: 8px;
    color: white;
}




.badge-detail-container {
    display: flex;
    justify-content: flex-end;
}


.badge-detail-heading {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    height: 100%;
    padding-top: 30%;
    padding-right: 12px;
}

.main-badge-slider-container {
    width: 100%;
}
.badge-slider-container {
    width: 70%;
}