.contact-us-container {
  margin-top: 30px;
  height: 500px;
}
.contact-us-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.contact-status-message {
  color: #f2059f;
  padding-top: 12px;
  padding-bottom: 12px;
}

@media (max-width: 767px) {
  .contact-us-container {
    margin-top: 30px;
    height: 500px;
  }
}
