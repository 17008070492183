.main-content{
  width: 100%;
  height: auto;
  overflow: hidden;
  .home-battles-wrapper{
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    .main-container{
      display: flex;
      flex-direction: column;
      gap: 5rem;
    }
   
  }
}