/* CntndrLanding.css */
.cntndr-landing {
    max-width: 1200px;
    margin: 0 auto;
    padding: 4rem 2rem;
    /* font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif; */
    color: white;
}

.hero {
    text-align: center;
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero h1 {
    font-size: 3rem;
    margin-bottom: 1.5rem;
    font-weight: 800;
    letter-spacing: -0.05em;
    color: white
}

.hero-subtitle {
    font-size: 1.25rem;
    color: white;
    max-width: 600px;
    margin: 0 auto;
    line-height: 1.6;
}

.features-container {
    /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem; */
    display: flex;
    flex-direction: column;
}

.feature {
    padding: 1.5rem;
    border-radius: 12px;
    /* border: 1px solid #eaeaea; */
    transition: all 0.2s ease;

    display: flex;
    flex-direction: row;
}

.feature-content {
    flex: 1;
}

.feature:hover {
    border-color: #000;
}

.feature-header > span {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    color: white;
    font-size: 25px;
}

.feature-number {
    background-color: #000;
    color: white;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 0.9rem;
}

.feature h2 {
    font-size: 1.25rem;
    font-weight: 700;
    margin: 0;
}

.feature p {
    color: white;
    line-height: 1.6;
    margin: 0;
    font-size: 1rem;
}

.features-row-container {
    display: flex;
    flex-direction: row;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .hero h1 {
        font-size: 2.25rem;
    }
    .features-row-container {
        display: flex;
        flex-direction: column;
    }

    /* .features-container {
    grid-template-columns: 1fr;
  } */
}

.feature-icon {
    padding-top: 15px;
    padding-left: 12px;
    padding-right: 12px;
}

.landing-header-text {
    font-size: 40px;
}

.cntndr-text {
    background: linear-gradient(90deg, #F2059F, #05C6FE);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}