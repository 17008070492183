.footer-container {

    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 16px;
}

@media (max-width: 767px) {
    .footer-container {

        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 14px;
        padding: 5px;
        font-size: 14px;
        margin-bottom: 35px;
    }
}