.contact-wrapper-main {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  /* .genre-content-wrapper {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-end;
  
      .genre-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 6.25rem;
      }
    }
  
    @media (max-width: 767px) {
      .chat-main-wrapper {
        padding-top: 8.125rem;
      }
    } */
}

.contact-banner-wrapper {
  width: 100%;
  height: 400px;
  position: relative;
  z-index: 1;
}

.contact-banner {
  position: absolute;
  top: 100px;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  /* padding-right: 5.625rem; */
  background-color: black;
}

.contact-banner-content {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  margin-left: 55px;
  gap: 0;
}

.contact-banner-title {
  text-align: center;
  font-size: 3.6875rem;
  font-style: normal;
  font-weight: 400;
  width: 100%;

  color: white;
  @media (max-width: 1119px) {
    font-size: 3.4375rem;
  }
  @media (max-width: 767px) {
    font-size: 2.5rem;
  }
}

.contact-banner-sub-title {
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  @media (max-width: 1119px) {
    font-size: 2.4375rem;
  }
  @media (max-width: 767px) {
    font-size: 1rem;
  }
}

.contact-banner-description {
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: rgba(255, 255, 255, 0.6);
}
.contact-banner-btn-wrap {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
}

.contact-page-title {
  color: white;
}
.contact-page-text {
  color: rgba(255, 255, 255, 0.6);
}
.contact-page-container {
  width: 100%;
  height: auto;
  display: flex;

  justify-content: flex-end;
  position: relative;
  top: 40px;
}

.contact-page-text-container {
  width: calc(100% - 235px);
  height: auto;
}

.contact-page-links {
  display: flex;
  flex-direction: column;
  padding-bottom: 18px;
}

.contact-page-links > div {
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  text-decoration: underline;
}

.contact-page-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* flex-wrap: wrap; */
  width: 100%;
}

.contact-page-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.contact-question-container {
  display: flex;
  flex-direction: column;
  /* flex-direction: row; */
  /* align-items: space-between; */

  position: relative;
  margin-top: 12px;

  padding-left: 12px;

  min-height: 68px;

  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  text-align: center;

  cursor: pointer;
  margin-right: 12px;
}

.contact-question-header {
  width: 100%;
  /* flex: 1; */
  display: flex;
  /* flex-direction: row; */
  /* align-items: space-between; */
  justify-content: space-between;
  /* align-items: center; */
  padding-top: 12px;
}

.contact-question-opened {
  min-height: 100px;
  height: auto;
}

.img-dropdown-icon {
  margin-right: 12px;
}

.contact-question-description {
  text-align: left;
  padding: 12px;
}

@media (max-width: 767px) {
  .contact-banner-wrapper {
    width: 100%;
    height: 250px;
    position: relative;
    z-index: 1;
  }

  .contact-page-text-container {
    width: 100%;
    height: auto;
  }
  .contact-banner {
    position: relative;
    top: 40px;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    /* padding-right: 5.625rem; */
    background-color: black;
  }

  .contact-page-row {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* flex-wrap: wrap; */
    width: 100%;
  }

  .contact-page-container {
    width: 100%;
    height: auto;
    display: flex;

    justify-content: center;
    position: relative;
    top: 40px;
  }

  .contact-banner-content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    margin-left: 0px;
    gap: 0;
  }
}
