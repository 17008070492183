.legal-wrapper-main {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  /* .genre-content-wrapper {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;

    .genre-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 6.25rem;
    }
  }

  @media (max-width: 767px) {
    .chat-main-wrapper {
      padding-top: 8.125rem;
    }
  } */
}

.legal-banner-wrapper {
  width: 100%;
  height: 400px;
  position: relative;
  z-index: 1;
}

.legal-banner {
  position: absolute;
  top: 100px;
  right: 0;
  width: 100%; /* calc(100% - 235px); */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  padding-right: 5.625rem;
  background-color: black;
}

.legal-banner-content {
  max-width: 800px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  gap: 0;
}

.legal-banner-title {
  text-align: center;
  font-size: 3.6875rem;
  font-style: normal;
  font-weight: 400;
  margin-left: 150px;

  color: white;
  @media (max-width: 1119px) {
    font-size: 3.4375rem;
  }
  @media (max-width: 767px) {
    font-size: 2.5rem;
  }
}

.legal-banner-description {
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: rgba(255, 255, 255, 0.6);
}
.legal-banner-btn-wrap {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
}

.legal-page-title {
  color: white;
}
.legal-page-text {
  color: rgba(255, 255, 255, 0.6);
}
.legal-page-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: 40px;
}

.legal-page-text-container {
  width: calc(100% - 235px);
  height: auto;
}

.legal-page-links {
  display: flex;
  flex-direction: column;
  padding-bottom: 18px;
}

.legal-page-links > div {
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  text-decoration: underline;
}

@media (max-width: 767px) {
  .legal-banner-wrapper {
    width: 100%;
    height: 250px;
    position: relative;
    z-index: 1;
  }

  .legal-page-text-container {
    width: calc(100% - 30px);
    height: auto;
  }
  .legal-banner {
    position: absolute;
    top: 40px;
    right: 0;
    width: 100%; /* calc(100% - 50px); */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    padding-right: 5.625rem;
    background-color: black;
  }

  .legal-banner-title {
    margin-left: 90px;
    @media (max-width: 1119px) {
      font-size: 3.4375rem;
    }
    @media (max-width: 767px) {
      font-size: 2.5rem;
    }
  }
}
