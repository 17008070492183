@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

$form-bg-color:rgba(255, 255, 255, 0.20);
$body-color:rgb(28, 28, 28);
$bgGray:#F7F7F8;
$white: rgba(255,255,255) !default;
$danger:#FF4B4B;
$success:#6DB143;
$warning:#FBD54D;
$primary:#F2059F;
$lightblue:#E5F0FF;
$secondary:rgba(82, 83, 84);
$btn-border-radius: .3125rem;
$btn-padding-y:.5rem !important;
$btn-padding-x:.875rem;
$btn-color:$white !important;
$btn-font-size:1rem !important;
$btn-font-family:"Mulish", sans-serif;
$btn-font-weight:500;
$input-height:2.25rem;
$input-bg:$form-bg-color;
$input-color:$white;
$input-focus-box-shadow:unset;
$input-placeholder-color:rgba(255,255,255,0.5);
$input-box-shadow:unset;
$input-focus-border-color:transparent !important;
$input-border-radius:.625rem;
$btn-line-height:1.375rem;
$input-padding-y:.6875rem;
$input-padding-x: 1.25rem;
$input-border-color:transparent;
$input-group-addon-bg: transparent;
$input-font-size:.875rem;
$form-check-input-bg:$input-bg;
$form-select-border-radius:.625rem !important;
$form-check-input-checked-bg-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='11' viewBox='0 0 12 11' fill='none'%3E%3Cpath fill-rule='evenodd' clipRule='evenodd' d='M11.5466 1.18956L5.71239 10.0697L0.755859 5.11313L1.2441 4.62489L5.60098 8.98176L10.9695 0.810425L11.5466 1.18956Z' fill='white'/%3E%3C/svg%3E");
$form-select-indicator:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="10" viewBox="0 0 18 10" fill="none"><path d="M0.849121 0.849609L8.78857 8.78906L16.728 0.849609" stroke="white"/></svg>');
$form-select-padding-y-sm:0.507rem;
$form-label-font-size:1.125rem;
$form-label-font-weight:600;
$form-check-label-color:$white !important;
$form-check-input-focus-box-shadow: unset;
$form-check-input-bg:transparent;
$form-check-input-border:1px solid $white;
$form-check-input-border-radius:.125rem;
$form-check-input-focus-border:$white;
$font-family-sans-serif:"Mulish", sans-serif !important;


//Modals
$modal-md:432px;
$modal-sm:324px;
$modal-content-border-color:transparent;
$modal-header-border-color: transparent;
$modal-footer-border-color:transparent;
$modal-header-padding:1.375rem 3.125rem 0;
$modal-content-border-radius: .625rem;
$modal-content-inner-border-radius: .625rem;
$modal-inner-padding:1.875rem 3.125rem;
$modal-content-bg: rgba($body-color,0.5);
$btn-close-bg:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");


//Tooltip
$tooltip-bg:$primary;
$tooltip-font-size:.75rem;

@import 'node_modules/bootstrap/scss/bootstrap.scss';
.modal-content{
    backdrop-filter: blur(20px);
}

@media(max-width:767px){
    .modal-header{
        padding:1.375rem 2rem 0;
    }
    
    .modal-body{
        padding:1.875rem 2rem ;
    }
}