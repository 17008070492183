.terms-container {
  font-size: 12px;
}
.terms-text {
  color: rgba(255, 255, 255, 0.6);
  white-space: nowrap;
}

.terms-container > div {
  text-align: justify;
  text-align-last: center;
  margin: 0 auto;
}
